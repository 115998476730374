import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        buildTime(formatString: "YYYY")
      }
    }
  `)
  return (
    <footer className="pt-48 text-center text-xs">
      © yuya.tkhs {site.buildTime}.
    </footer>
  )
}

export default Footer
