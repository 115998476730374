import React from "react"

import Header from './header'
import Footer from './footer'

import '@fontsource/montserrat'
import '@fontsource/noto-sans-jp/300.css'
import '@fontsource/noto-sans-jp/700.css'
import "./tailwind.css"

const Layout = ({ children, isH1 }) => (
  <div className="font-body subpixel-antialiased text-gray-700 leading-extra-relaxed">
    <Header isH1={isH1} />
      { children }
    <Footer />
  </div>
)

export default Layout
