import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/ui/layout'
import SEO from '../components/ui/seo'
import Article from '../components/ui/list-card-article'

const IndexPage = ({data}) => {
  return (
    <Layout isH1={true}>
      <SEO />
      <main>
        <div className="pt-24 px-6 w-full max-w-1800 mx-auto flex flex-row flex-wrap justify-center">
          <Article
            title="Investment Tech Hack"
            description="投資のこと"
            imageFixed={data.ith.childImageSharp.fixed}
            imageAlt="Investment Tech Hack"
            linkTo="https://investment.abbamboo.com/"
          />
        </div>
        <div className="pt-24 px-6 w-full max-w-1800 mx-auto flex flex-row flex-wrap justify-center">
          <Article
            title="Twitter"
            description=""
            imageFixed={data.twitter.childImageSharp.fixed}
            imageAlt="Twitter"
            linkTo="https://twitter.com/u_ya41216132"
          />
          <Article
            title="Instagram"
            description=""
            imageFixed={data.insta.childImageSharp.fixed}
            imageAlt="Twitter"
            linkTo="https://www.instagram.com/yuya_takahashi_/"
          />
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    insta: file(relativePath: {eq: "insta.png"}) {
      childImageSharp {
        fixed(width: 312) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    twitter: file(relativePath: {eq: "twitter.png"}) {
      childImageSharp {
        fixed(width: 312) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    ith: file(relativePath: {eq: "ith.png"}) {
      childImageSharp {
        fixed(width: 312) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
