import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = () => {
  const data = useStaticQuery( graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          datePublished
          lang
          locale
        }
        buildTime(formatString: "yyyy-MM-DD")
      }
      thumb: file(relativePath: {eq: "thumb.png"}) {
        childImageSharp {
          original {
            height
            src
            width
          }
        }
      }
      logo: file(relativePath: {eq: "icon.png"}) {
        childImageSharp {
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const site = {
    lang: data.site.siteMetadata.lang,
    locale: data.site.siteMetadata.locale,
    title: data.site.siteMetadata.title,
    description: data.site.siteMetadata.description,
    url: data.site.siteMetadata.siteUrl,
    author: data.site.siteMetadata.siteAuthor,
    fbAppId: data.site.siteMetadata.fbAppId,
    date: {
      published: data.site.siteMetadata.datePublished,
      modified: data.site.buildTime,
    }
  }
  site.image = {
    url: `${site.url}${data.thumb.childImageSharp.original.src}`,
    width: data.thumb.childImageSharp.original.width,
    height: data.thumb.childImageSharp.original.height,
  }
  site.logo = {
    url: `${site.url}${data.logo.childImageSharp.original.src}`,
    width: data.logo.childImageSharp.original.width,
    height: data.logo.childImageSharp.original.heigh,
  }

  // 出力する
  return (
    <Helmet>
      <html lang={site.lang}></html>
      <title>{site.title}</title>
      <meta name="description" content={site.description}/>
      <link rel="canonical" href={site.url} />
      <meta property="og:site_name" content={site.title} />
      <meta property="og:title" content={site.title} />
      <meta property="og:description" content={site.description} />
      <meta property="og:url" content={site.url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={site.locale} />
      <meta property="og:image" content={site.image.url} />
      <meta property="og:image:width" content={site.image.width} />
      <meta property="og:image:height" content={site.image.height} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta name="google-site-verification" content="" />
    </Helmet>
  )
}

export default SEO
