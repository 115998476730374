import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"


const Article = ({title, description, imageFixed, imageAlt, linkTo, key}) => {
  return (
    <article className="transition-all duration-300 flex w-full max-w-sm py-6" key={key}>
      <Link className="w-full" to={linkTo}>
        <figure className="w-76 h-38 mx-auto rounded-md overflow-hidden shadow-xl">
          <Img
            className="transform hover:scale-110 transition duration-300"
            fixed={imageFixed}
            alt={imageAlt}
          />
        </figure>
        <div className="pt-5 px-4 text-center">
          <h3 className="text-base font-bold leading-snug">{title}</h3>
          <span className="text-xs italic text-gray-500">{description}</span>
        </div>
      </Link>
    </article>
  )
}

export default Article
